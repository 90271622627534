@use './colors' as *;
@use '@angular/material' as mat;

/* Global overrides */

:root {
    @include mat.button-overrides(
        (
            filled-container-color: $blue3,
            filled-label-text-color: white,
            filled-label-text-transform: uppercase,
            filled-label-text-size: 14px
        )
    );
}

/* Tooltip */

.mat-tooltip {
    background-color: white;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 14px;
    position: relative;
    bottom: 10px;
    overflow: unset !important;
    &:after {
        content: ' ';
        position: absolute;
        top: 50%;
        right: -15px;
        margin-top: -8px;
        border-width: 8px 8px 8px 8px;
        border-style: solid;
        border-color: transparent transparent transparent white;
    }
    &.right-tooltip {
        background-color: white;
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 14px;
        position: relative;
        bottom: 0px;
        left: 10px;
        overflow: unset !important;
        &:after {
            content: ' ';
            position: absolute;
            top: 50%;
            right: 100%;
            margin-top: -8px;
            border-width: 8px 8px 8px 8px;
            border-style: solid;
            border-color: transparent white transparent transparent;
        }
    }
    &.easitraining {
        color: #007fad;
    }
    &.easilearning {
        color: #199fe2;
    }
    &.easiconnect {
        color: #16282e;
    }
    &.easicloud {
        color: #e8b600;
    }
    &.easicall {
        color: #f36;
    }
    &.easiforum {
        color: #955099;
    }
    &.easimedia {
        color: #01a59b;
    }
    &.easichat {
        color: #102c3a;
    }
    &.machinepaysdelaloire {
        color: #424242;
    }
    &.msoffice {
        color: #dc5928;
    }
    &.orthodidacte_formateur {
        color: #424242;
    }
}

/* Input */

.mat-mdc-form-field-subscript-wrapper {
    display: none;
}

/* Dialog */

.cdk-overlay-pane.mat-mdc-dialog-panel {
    --mat-dialog-container-max-width: 100vw;
}

.mat-mdc-dialog-container {
    margin: 0 !important;
    padding: 0 !important;
    overflow: visible !important;
    .mdc-dialog__surface {
        overflow: visible !important;
        .mat-mdc-dialog-content {
            letter-spacing: unset !important;
            line-height: unset !important;
            font-size: unset !important;
            font-weight: unset !important;
        }
    }
}

.new-dialog {
    .mat-mdc-dialog-container {
        margin: 0 !important;
        padding: 0 !important;
        overflow: visible !important;
        .mdc-dialog__surface {
            overflow: visible !important;
            .mat-mdc-dialog-component-host {
                .header {
                    background-color: $blue3;
                    color: $white;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 40px;

                    [class^='icon-'] {
                        font-size: 24px;
                        padding: 8px;
                    }
                    .label {
                        flex-grow: 1;
                        text-align: center;
                        text-transform: uppercase;
                        font-weight: bold;
                    }
                    mat-icon {
                        color: $white;
                    }
                }
            }
            .mat-mdc-dialog-content {
                letter-spacing: unset !important;
                line-height: unset !important;
                font-size: unset !important;
                font-weight: unset !important;
            }
            .footer {
                background-color: $blue3;
                color: $white;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 10px;
                padding: 10px;
                .mdc-button {
                    width: 100%;
                    background-color: $white;
                    color: $blue3;
                    border-radius: 5px;
                    text-transform: uppercase;
                    font-size: 14px;
                }
            }
        }
    }
}

.background-color-transparent {
    // Used in user-details-component
    .mat-dialog-container {
        background-color: transparent;
        overflow: visible;
        @media (max-width: 1536px) {
            overflow: auto;
            box-shadow: none;
        }
    }
}
