@use 'colors' as *;
@use '@angular/material' as mat;

@import url('https://cdn.easi-training.fr/assets/css/icons.css');
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,400italic,600,700');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

@include mat.elevation-classes();
@include mat.app-background();

html,
body {
    margin: 0;
    padding: 0;
    overflow-y: hidden;
    overflow-x: hidden;

    @include mat.theme(
        (
            color: (
                theme-type: light,
                primary: mat.$azure-palette,
                tertiary: mat.$blue-palette
            ),
            typography: 'Source Sans Pro',
            density: 0
        )
    );
}

* {
    font-family: 'Source Sans Pro', sans-serif;
}

.cdk-global-scrollblock {
    overflow-y: hidden !important;
}

#header-container {
    position: fixed;
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-button:start:decrement,
::-webkit-scrollbar-button:end:increment {
    display: block;
    height: 0;
    background-color: transparent;
}

::-webkit-scrollbar-track-piece {
    border-radius: 8px;
}

::-webkit-scrollbar-thumb {
    height: 50px;
    background-color: $grey2;
    border-radius: 8px;
    z-index: 9999;
}

.recommandedConfiguration {
    border-collapse: collapse;
    text-align: center;
    width: 70%;
    margin: auto;
    margin-top: 30px;
    line-height: 30px;
    font-size: 16px;
    td {
        border: 1px solid white;
    }
    .sub_title {
        background-color: $blue1;
        color: white;
        font-weight: bold;
        a {
            color: white;
        }
    }
    .blue_line {
        background-color: aliceblue;
    }
    .white_line {
        background-color: white;
    }
}
.question_3 {
    .img-wrap {
        width: 50%;
        height: 50%;
        position: relative;
        display: inline-block;
        overflow: hidden;
        margin: 0;
        margin-left: auto;
        margin-right: auto;
        margin-top: 30px;
        margin-bottom: 30px;
        display: block;
    }
    p {
        margin: 1.6% 0;
    }
    ul {
        margin-top: 5px;
        margin-bottom: 5px;
        margin-left: inherit;
    }
    .link {
        color: $blue1;
        cursor: pointer;
    }
    table {
        border: none;
        border-spacing: 4px;
        width: 100%;
        text-align: center;

        .red-border {
            border: 2px solid red;
        }
        th {
            font-weight: normal;
            border: none;
            color: black !important;
            padding-bottom: 4px;
        }
        td {
            padding: 3px 2px;
        }
    }
    .separate-blocks {
        margin-top: 50px;
    }
    .key {
        border: none;
        color: black !important;
    }
    .mandatory {
        border: 1px solid black;
    }
    .optional {
        border: 1px solid $grey2;
        color: $green1;
    }
    .ignored {
        border: 1px solid $grey2;
        color: $orange3;
    }
    .forbidden {
        border: 1px solid $grey2;
        color: $grey2;
    }
    .code {
        font-style: italic;
        color: $grey1;
        margin: 5px 20px;
        display: inline-block;
    }

    #legend {
        width: unset;
        border-spacing: 10px;
    }
    #dlCSV {
        background-color: #005981;
        color: white;
        width: 25%;
        margin-left: 75%;
        padding: 5px;
        border: 1px solid #008dce;
        border-radius: 3px;
        cursor: pointer;
    }
}
.question_4 {
    .img-wrap-full {
        width: 65%;
        position: relative;
        display: inline-block;
        overflow: hidden;
        margin: 0;
        margin-left: auto;
        margin-right: auto;
        margin-top: 30px;
        margin-bottom: 30px;
        display: block;
    }
    .img-wrap {
        width: 32%;
        position: relative;
        display: inline-block;
        overflow: hidden;
        margin: 0;
        margin-left: auto;
        margin-right: auto;
        display: block;
    }
    p {
        margin: 1.6% 0;
        b {
            margin: 40px 0px 30px 0px;
            display: block;
            font-size: 16px;
        }
        .center {
            display: block;
            text-align: center;
        }
        &.important {
            text-align: center;
            font-weight: bold;
        }
    }
    ol {
        position: relative;
        left: 20%;
    }
}
